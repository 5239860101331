// STARTED TOP nodejs/browser hack
(function() {
// FINISHED TOP nodejs/browser hack

  const ERROR_MSG_INPUT = 'Input must be an string, Buffer or Uint8Array';

  // For convenience, let people hash a string, not just a Uint8Array
  function normalizeInput(input) {
    let ret;
    if (input instanceof Uint8Array) {
      ret = input;
    } else if (input instanceof Buffer) {
      ret = new Uint8Array(input);
    } else if (typeof (input) === 'string') {
      ret = new Uint8Array(Buffer.from(input, 'utf8'));
    } else {
      throw new Error(ERROR_MSG_INPUT);
    }
    return ret;
  }

  // Converts a Uint8Array to a hexadecimal string
  // For example, toHex([255, 0, 255]) returns "ff00ff"
  function toHex(bytes) {
    return Array.prototype.map.call(bytes, function(n) {
      return (n < 16 ? '0' : '') + n.toString(16);
    }).join('');
  }

  // Converts any value in [0...2^32-1] to an 8-character hex string
  function uint32ToHex(val) {
    return (0x100000000 + val).toString(16).substring(1);
  }

  // For debugging: prints out hash state in the same format as the RFC
  // sample computation exactly, so that you can diff
  function debugPrint(label, arr, size) {
    let msg = '\n' + label + ' = ';
    for (let i = 0; i < arr.length; i += 2) {
      if (size === 32) {
        msg += uint32ToHex(arr[i]).toUpperCase();
        msg += ' ';
        msg += uint32ToHex(arr[i + 1]).toUpperCase();
      } else if (size === 64) {
        msg += uint32ToHex(arr[i + 1]).toUpperCase();
        msg += uint32ToHex(arr[i]).toUpperCase();
      } else throw new Error('Invalid size ' + size);
      if (i % 6 === 4) {
        msg += '\n' + new Array(label.length + 4).join(' ');
      } else if (i < arr.length - 2) {
        msg += ' ';
      }
    }
    console.log(msg);
  }

  // For performance testing: generates N bytes of input, hashes M times
  // Measures and prints MB/second hash performance each time
  function testSpeed(hashFn, N, M) {
    let startMs = new Date().getTime();

    const input = new Uint8Array(N);
    for (var i = 0; i < N; i++) {
      input[i] = i % 256;
    }
    const genMs = new Date().getTime();
    console.log('Generated random input in ' + (genMs - startMs) + 'ms');
    startMs = genMs;

    for (i = 0; i < M; i++) {
      const hashHex = hashFn(input);
      const hashMs = new Date().getTime();
      const ms = hashMs - startMs;
      startMs = hashMs;
      console.log('Hashed in ' + ms + 'ms: ' + hashHex.substring(0, 20) + '...');
      console.log(Math.round(N / (1 << 20) / (ms / 1000) * 100) / 100 + ' MB PER SECOND');
    }
  }

  // STARTED BOTTOM nodejs/browser hack
  const exports = (() => {
    const exports = {};
    exports.normalizeInput= normalizeInput;
    exports.toHex= toHex;
    exports.debugPrint= debugPrint;
    exports.testSpeed= testSpeed;
    return exports;
  })();

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = exports;
  } else {
    window.blakejsUtil = exports;
  }
})();
// FINISHED BOTTOM nodejs/browser hack
